import _ from 'lodash';
import { useObserver } from 'mobx-react-lite';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useUser from 'src/hooks/use-user';
import useCart from 'src/hooks/use-cart';

import { useHasValidSponsoredBrandPlacement } from 'src/utils/ads/hooks/sponsored-brand';
import { useAdsEnabled } from 'src/utils/ads/hooks/use-ads-enabled';
import { ADS_FETCH_POLICY, useAdInventoryId } from 'src/utils/ads/helpers';
import { SponsoredBrandQuery } from 'src/utils/ads/types';
import { PersonalizedProductsQuery } from 'src/personalization/data-layer/types';
import { useGetPersonalizedProductsQuery, useGetSponsoredBrandQuery } from 'types/graphql';
import { useShouldSkipPersonalization } from './use-skip-ads-rules';

type UseProductsForHomePageParams = {
  dispensaryId: string;
};

export type UseProductsForHomePageReturn = {
  personalizedProductsQuery: PersonalizedProductsQuery;
  sponsoredBrandQuery: SponsoredBrandQuery;
};

export function useProductsForHomePage({ dispensaryId }: UseProductsForHomePageParams): UseProductsForHomePageReturn {
  const user = useUser();
  const cart = useCart();
  const flags = useFlags();
  const menuType = useObserver(() => cart.menuType);
  const isLoggedIn = useObserver(() => user.isLoggedIn);
  const adsEnabled = useAdsEnabled();
  const skipPersonalization = useShouldSkipPersonalization();

  const hasValidSponsoredBrandPlacement = useHasValidSponsoredBrandPlacement('home-page');
  const isSponsoredContentEnabledForPersonalization =
    flags['growth.ads.enable-sponsored-content-personalization.rollout'];

  const inventoryId = useAdInventoryId(dispensaryId);
  const personalizedProductLimit = isLoggedIn ? 20 : 3;

  const personalizedProductsQuery = useGetPersonalizedProductsQuery({
    fetchPolicy: `network-only`,
    skip: !dispensaryId || skipPersonalization,
    variables: {
      input: {
        dispensaryId,
        limit: personalizedProductLimit,
        filter: {
          menuType,
        },
      },
    },
  });

  const sponsoredBrandQuery = useGetSponsoredBrandQuery({
    fetchPolicy: ADS_FETCH_POLICY,
    skip:
      !dispensaryId ||
      !adsEnabled ||
      (!isSponsoredContentEnabledForPersonalization && !hasValidSponsoredBrandPlacement),
    variables: {
      input: {
        dispensaryId,
        inventoryId,
        maximumProducts: 20,
        minimumProducts: 3,
      },
    },
  });

  return {
    personalizedProductsQuery: _.pick(personalizedProductsQuery, ['data', 'loading', 'error']),
    sponsoredBrandQuery: _.pick(sponsoredBrandQuery, ['data', 'loading', 'error']),
  };
}
